import React, {useEffect, useState} from 'react';
import {GridColDef, GridRowParams} from "@mui/x-data-grid";
import {MatchInfoDto} from "sandy-shared/src/types/match-info.type";
import {Filter} from "../../shared/types/filter";
import {UrlFilterAwareDataGrid} from "../../shared/components/UrlFilterAwareDataGrid";
import {
  Box,
  Button,
  Modal,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {getMatches, deleteMatch} from "../../services/match.service";
import MatchInfoComponent from "./MatchInfoComponent";
import {useNavigate} from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

const showAllFilter: Filter<MatchInfoDto> = {
  displayName: 'All',
  urlParam: 'showAll',
  filterFunction: (values: MatchInfoDto[]) => values
}
const allFilters = [showAllFilter];
const defaultFilter = showAllFilter;

const matchRowBuilder = (match: MatchInfoDto) => {
  const scores = match.sets.map(set => `${set.homeScore}-${set.awayScore}`).join(', ');
  return {
    id: match.matchId,
    homePartnership: `${match.homePartnership.playerOne.lastName}/${match.homePartnership.playerTwo.lastName}`,
    awayPartnership: `${match.awayPartnership.playerOne.lastName}/${match.awayPartnership.playerTwo.lastName}`,
    scores,
    type: match.type,
    status: match.status,
    date: match.date
  }
}

const MatchesListPage: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedMatchId, setSelectedMatchId] = useState<string | undefined>(undefined);
  const [matches, setMatches] = useState<MatchInfoDto[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [matchToDelete, setMatchToDelete] = useState<string | undefined>(undefined);

  useEffect(() => {
    refreshMatches();
  }, []);

  const refreshMatches = () => {
    setIsLoading(true);
    getMatches()
      .then(matches => setMatches(matches))
      .catch(e => {
        console.error('Error getting matches', e)
        enqueueSnackbar(`Error getting matches: ${e}`, {variant: 'error', autoHideDuration: 5000})
      })
      .finally(() => setIsLoading(false));
  }

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/match-statting/${params.id}`);
  };

  const handleCloseMatchInfo = () => {
    setSelectedMatchId(undefined);
  }

  const handleSaveMatchInfo = () => {
    setSelectedMatchId(undefined);
    refreshMatches();
  }

  const handleAddNewMatchClick = () => {
    setSelectedMatchId('NEW');
  }

  const handleDeleteClick = (event: React.MouseEvent, matchId: string) => {
    event.stopPropagation();
    setMatchToDelete(matchId);
    setDeleteDialogOpen(true);
  }

  const handleConfirmDelete = () => {
    if (matchToDelete) {
      deleteMatch(matchToDelete)
        .then(() => {
          enqueueSnackbar('Match deleted successfully', {variant: 'success', autoHideDuration: 5000});
          refreshMatches();
        })
        .catch(e => {
          console.error('Error deleting match', e);
          enqueueSnackbar(`Error deleting match: ${e}`, {variant: 'error', autoHideDuration: 5000});
        })
        .finally(() => {
          setDeleteDialogOpen(false);
          setMatchToDelete(undefined);
        });
    }
  }

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setMatchToDelete(undefined);
  }

  const matchColumns: GridColDef[] = [
    { field: 'homePartnership', headerName: 'Home Partnership', width: 200 },
    { field: 'awayPartnership', headerName: 'Away Partnership', width: 200 },
    { field: 'scores', headerName: 'Scores', width: 250 },
    { field: 'type', headerName: 'Type', width: 100 },
    { field: 'status', headerName: 'Status', width: 100 },
    { field: 'date', headerName: 'Date', width: 250 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <IconButton color="error" onClick={event => handleDeleteClick(event, params.id as string)}>
          <DeleteIcon />
        </IconButton>
      )
    }
  ];

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: '25px'}}>
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2}}>
        <Button variant="contained" onClick={handleAddNewMatchClick}>Create New Match</Button>
      </Box>
      <UrlFilterAwareDataGrid
        columns={matchColumns}
        rowBuilder={matchRowBuilder}
        data={matches}
        onRowClick={handleRowClick}
        allFilters={allFilters}
        defaultFilters={[defaultFilter]}
        loading={isLoading}
      />
      <Modal
        open={!!selectedMatchId}
        onClose={() => handleCloseMatchInfo()}
      >
        <MatchInfoComponent matchId={selectedMatchId === 'NEW' ? undefined : selectedMatchId} onClose={() => handleCloseMatchInfo()} onSave={() => handleSaveMatchInfo()}/>
      </Modal>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCancelDelete}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this match?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} variant={'contained'} color="primary">Cancel</Button>
          <Button onClick={handleConfirmDelete} variant={'contained'} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
};

export default MatchesListPage;